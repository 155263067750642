import React, { Component } from "react"
import LocalNavigation from "../../components/LocalNavigation"
import Sticky2colmun from "../../components/Sticky2column"
import LayoutBasic from "../../components/LayoutBasic"
import BasicText from "../../components/BasicText"
import RubyConvert from "../../components/RubyConvert"
import Seo from "../../components/Seo"
import css from "./stylesheets/what-is-kaigo.module.scss"
import CenterContentLayout from "../../components/CenterContentLayout"

class PageBasicInformation extends Component {
  constructor(props) {
    super(props)
    this.doClickLangButton = this.doClickLangButton.bind(this)
  }

  doClickLangButton() {
    let layoutBasic = this.ref.layoutBasicInstance
    if (typeof layoutBasic !== "undefined" && layoutBasic !== null) {
      layoutBasic.ref.globalNavigationInstance.openLanguageMenu()
    }
  }

  render() {
    this.ref = {}

    return (
      <div>
        <Seo title={`WHAT is KAIGO?`} />
        <LayoutBasic
          hasLocalNavi={true}
          reference={this.ref}
          lang={`easyja`}
          style={{ marginBottom: `0` }}
        >
          <LocalNavigation
            links={[
              { title: `はじめに`, link: `/what-is-kaigo/#section-1` },
              { title: `KAIGO とは`, link: `/what-is-kaigo/#section-2` },
              {
                title: `わたしたちについて`,
                link: `/what-is-kaigo/#section-3`,
              },
            ]}
          />
          <CenterContentLayout>
            <BasicText>
              <h1 className={css.pageTitle}>WHAT is KAIGO?</h1>
            </BasicText>

            <Sticky2colmun id={`section-1`}>
              {/* column left*/}
              <div>
                <h2>はじめに</h2>
              </div>
              {/* /column left*/}

              {/* column right*/}
              <div>
                <p>
                  こんにちは！
                  <br />
                  <RubyConvert>{`{『KAIGO in JAPAN』は、}{[日本/にほん]で}{[介護/かいご]の}{[仕事/しごと]を}{しようと}{[考/かんが]えている}{[人/ひと]のための}{ウェブサイトです。}{[介護/かいご]の[仕事/しごと]や}{[日本/にほん]での}{[生活/せいかつ]について、}{みなさんが}{[気/き]になる}{いろいろな}{ことに}{[答/こた]えたいと}{[思/おも]っています。}{[先輩/せんぱい]たちの}{リアルな}{[話/はなし]も}{[紹介/しょうかい]します。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{[日本/にほん]で}{[働/はたら]いている}{[先輩/せんぱい]たちの}{インタビュー、}{[日本/にほん]と}{[介護/かいご]についての}{[基本/きほん][情報/じょうほう]、}{よく}{ある}{[質問/しつもん]}{などが} {あります。}`}</RubyConvert>
                  <RubyConvert>{`{「やさしい[日本語/にほんご]（Easy Japanese）」}{「[日本語/にほんご]」}{「[英語/えいご]」から}{[選/えら]んで、}{ぜひ}{[読/よ]んでください。}`}</RubyConvert>
                </p>
                <p>
                  <RubyConvert>{`{やさしい[日本語/にほんご]}{[監修/かんしゅう]}`}</RubyConvert>
                  ｜
                  <a
                    href="https://slow-communication.jp"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    スローコミュニケーション
                  </a>
                </p>
                <p className={`commonButton`}>
                  <button onClick={this.doClickLangButton}>
                    <div>
                      <span>LANGUAGE</span>
                    </div>
                  </button>
                </p>
              </div>
              {/* /column right*/}
            </Sticky2colmun>

            <Sticky2colmun id={`section-2`}>
              {/* column left*/}
              <div>
                <h2>KAIGOとは</h2>
              </div>
              {/* /column left*/}

              {/* column right*/}
              <div>
                <p>
                  <RubyConvert>{`{[人/ひと]は}{[高齢/こうれい]に}{なったり、}{[心/こころ]や}{[体/からだ]に} {[障害/しょうがい]が}{あったり}{すると、}{[日常/にちじょう][生活/せいかつ]を}{おくる}{ことが}{[難/むずか]しくなります。}{[介護/かいご]とは、}{そのような}{[人/ひと]に}{[対/たい]して、}{その}{[人/ひと]の}{[状況/じょうきょう]に} {あわせた}{[支援/しえん]を}{する}{ことです。}{[具体的/ぐたいてき]には、}{[食事/しょくじ]、}{[入浴/にゅうよく]、}{[排/はい]せつ、}{[身/み]の[回/まわ]りの}{[世話/せわ]}{などを}{する} {ことです。}{[生活/せいかつ]の}{なかの}{[小/ちい]さな}{ことも}{[一/ひと]つひとつ}{[観察/かんさつ]しながら、}{[毎日/まいにち]の}{[生活/せいかつ]を}{[整/ととの]えていきます。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{[日本/にほん]では、}{[介護/かいご]が}{[必要/ひつよう]な}{[人/ひと]は、}{[国/くに]や[都道府県/とどうふけん]の}{[制度/せいど]を}{[使/つか]って、}{[介護/かいご]を}{[受/う]ける}{ことが}{できます。}{このような}{[制度/せいど]が}{あるので、}{[家族/かぞく]}{など}{[介護/かいご]を}{してくれる}{[人/ひと]が}{いなくても、}{「[生/い]きる」[権利/けんり]が}{[守/まも]られます。}{そして、}{[高齢/こうれい]の}{[人/ひと]や}{[障害/しょうがい]が}{ある}{[人/ひと]}{[自身/じしん]が、}{[社会/しゃかい]に}{[参加/さんか]しやすくなります。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{また、}{[介護/かいご]サービスは、}{[高齢/こうれい]の}{[人/ひと]や}{[障害/しょうがい]が}{ある}{[人/ひと]の}{[家族/かぞく]にとっても}{よい}{サービスです。}{[家族/かぞく]が}{[介護/かいご]する}{[時間/じかん]が}{[減/へ]って、}{[働/はたら]いたり}{[自分/じぶん]の}{[時間/じかん]を}{もったり}{できるからです。}{[介護/かいご]は、}{[社会/しゃかい]にとって}{[大切/たいせつ]な}{[仕事/しごと]です。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{しかし}{[日本/にほん]では、}{[若/わか]い}{[人/ひと]が}{[少/すく]なくて} {[高齢/こうれい]の}{[人/ひと]が}{[増/ふ]えているので、}{[介護/かいご]の}{[仕事/しごと]を}{する}{[人/ひと]が}{[足/た]りなくなっています。}`}</RubyConvert>
                </p>
                <p>
                  <RubyConvert>{`{[介護/かいご]は、}{[専門的/せんもんてき]な}{[仕事/しごと]です。}{[科学的/かがくてき]に}{[人間/にんげん]を}{[知/し]る}{ことや、}{いろいろな}{[工夫/くふう]が}{[必要/ひつよう]です。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{[大切/たいせつ]なのは、}{[次/つぎ]の}{３つです。}`}</RubyConvert>
                  <br />
                </p>

                <h3 className={css.specialHeading}>
                  <i
                    className={
                      css.specialHeadingPrefix +
                      ` ` +
                      css.specialHeadingPrefixIsTall
                    }
                  >
                    1.
                  </i>
                  <div>
                    <RubyConvert>{`{[高齢/こうれい]の}{[人/ひと]や}{[障害/しょうがい]が}{ある}{[人/ひと]の}{[毎日/まいにち]の}{[生活/せいかつ]を}{[整/ととの]える}`}</RubyConvert>
                  </div>
                </h3>
                <p>
                  <RubyConvert>{`{[介護/かいご]の}{[大切/たいせつ]な}{[仕事/しごと]の}{1つは、}{[生活/せいかつ]を}{[整/ととの]える}{ことです。}{きれいな}{[空気/くうき]、}{[太陽/たいよう]の}{[光/ひかり]、}{[掃除/そうじ]、}{[洗濯/せんたく]、}{[食事/しょくじ]、}{[入浴/にゅうよく]、}{[着替/きが]え、}{[排/はい]せつ、}{[寝/ね]る}{ところ}{などを} {[整/ととの]えます。}{このような}{ことが、}{[生/い]きる}{ことを}{[支/ささ]えています。}{[生活/せいかつ]の}{なかの}{[小/ちい]さな}{ことも}{[一/ひと]つひとつ}{[観察/かんさつ]しながら、}{[一番/いちばん]}{よい}{[整/ととの]え[方/かた]を}{[考/かんが]えます。}{そして}{それを}{[実際/じっさい]に}{[行/おこな]っていきます。}`}</RubyConvert>
                </p>

                <h3 className={css.specialHeading}>
                  <i
                    className={
                      css.specialHeadingPrefix +
                      ` ` +
                      css.specialHeadingPrefixIsTall
                    }
                  >
                    2.
                  </i>
                  <div>
                    <RubyConvert>{`{その}{[人/ひと]が}{もっている}{[力/ちから]を}{[使/つか]って、}{[自立/じりつ]のための}{[支援/しえん]を}{する}`}</RubyConvert>
                  </div>
                </h3>
                <p>
                  <RubyConvert>{`{[介護/かいご]では、}{その}{[人/ひと]が}{できない}{ことを}{[支援/しえん]します。}{その}{[人/ひと]が}{[自分/じぶん]で}{できる}{ことは}{[自分/じぶん]で}{します。}{[時間/じかん]が}{かかっても}{いいです。}{たとえば、}{[自分/じぶん]で}{トイレに}{[行/い]ける}{[人/ひと]は}{[自分/じぶん]で}{トイレに}{[行/い]く。}{[自分/じぶん]で}{[皿/さら]や}{コップを}{[洗/あら]える}{[人/ひと]は}{[自分/じぶん]で}{[洗/あら]う。}{[介護/かいご]では、}{その}{[人/ひと]の}{[状態/じょうたい]や[様子/ようす]を}{[見/み]て、}{[何/なに]が}{できて、}{[何/なに]が} {できないかを}{[知/し]る}{[力/ちから]が}{[大切/たいせつ]です。}`}</RubyConvert>
                </p>

                <h3 className={css.specialHeading}>
                  <i
                    className={
                      css.specialHeadingPrefix +
                      ` ` +
                      css.specialHeadingPrefixIsTall
                    }
                  >
                    3.
                  </i>
                  <div>
                    <RubyConvert>{`{「[社会/しゃかい]の}{なかで}{[生/い]きる}{[人間/にんげん]」として}{[支援/しえん]する}`}</RubyConvert>
                  </div>
                </h3>
                <p>
                  <RubyConvert>{`{[家族/かぞく]や}{[近所/きんじょ]の[人/ひと]との}{[関係/かんけい]を}{[整/ととの]えていくのも}{[介護/かいご]の}{[仕事/しごと]です。}{[買/か]い[物/もの]に}{[出/で]かけたり、}{[近所/きんじょ]の}{[人/ひと]と}{[会話/かいわ]を}{[楽/たの]しんだり}{するのも}{[大切/たいせつ]な}{ことです。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{`{また、}{[残/のこ]りの}{[人生/じんせい]を}{どのように}{[過/す]ごしたいかについて、}{[高齢/こうれい]の}{[人/ひと]と}{[家族/かぞく]と}{[介護者/かいごしゃ]が}{[一緒/いっしょ]に}{[考/かんが]えたりも}{します。}{[高齢/こうれい]の}{[人/ひと]と}{[家族/かぞく]が}{お[墓参/はかまい]りや}{お[祈/いの]りに}{[行/い]く} {ときに、}{[介護者/かいごしゃ]が}{[一緒/いっしょ]に}{[行/い]く}{ことも}{あります。}`}</RubyConvert>
                  <br />
                  <RubyConvert>{``}</RubyConvert>
                </p>

                <h3 className={css.Heading3h}>
                  <RubyConvert>{`[介護/かいご][施設/しせつ]の1[日/にち]`}</RubyConvert>
                  <small>(3:47)</small>
                </h3>

                <div className={`fixedRatioBox is-16x9`}>
                  <iframe
                    width="560"
                    height="315"
                    src="https://www.youtube.com/embed/i8hNvS-DbTk"
                    frameBorder="0"
                    allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                    allowFullScreen
                    title="介護施設の1日"
                  ></iframe>
                </div>
              </div>
              {/* /column right*/}
            </Sticky2colmun>

            <Sticky2colmun id={`section-3`}>
              {/* column left*/}
              <div>
                <h2>わたしたちについて</h2>
              </div>
              {/* /column left*/}

              {/* column right*/}
              <div>
                <h3 className={css.specialHeading}>
                  <RubyConvert>{`{[福祉/ふくし]で}{[楽/たの]しい}{[社会/しゃかい]を}{つくる}`}</RubyConvert>
                </h3>
                <p>
                  <RubyConvert>{`{わたしたち}{FUKUSHI FOR CONVIVIALITYは、}{[子/こ]ども、}{[障害/しょうがい]が}{ある} {[人/ひと]、}{[高齢/こうれい]の}{[人/ひと]}{など}{みんなが、}{[安心/あんしん]して}{[地域/ちいき]で}{[暮/く]らす}{ことが}{できる}{[楽/たの]しい}{[社会/しゃかい]、}{ともに}{[生/い]きる}{[社会/しゃかい]を}{[目指/めざ]しています。}{そのために、}{いろいろな}{[活動/かつどう]を}{[行/おこな]っています。}`}</RubyConvert>
                </p>
                <dl>
                  <dt>
                    <RubyConvert>{`{[団体/だんたい]の}{[名前/なまえ]:}`}</RubyConvert>
                  </dt>
                  <dd>
                    <RubyConvert>{`{[一般/いっぱん][社団/しゃだん][法人/ほうじん] }`}</RubyConvert>
                    FUKUSHI FOR CONVIVIALITY
                  </dd>
                  <dt>
                    <RubyConvert>{`{[代表/だいひょう]の}{[人/ひと]の}{[名前/なまえ]:}`}</RubyConvert>
                    <br />
                    <small>
                      <RubyConvert>{`{([共同/きょうどう][代表/だいひょう])}`}</RubyConvert>
                    </small>
                  </dt>
                  <dd>
                    <RubyConvert>{`{[馬場/ばば][拓也/たくや]（[社会/しゃかい][福祉/ふくし][法人/ほうじん]}{[愛川/あいかわ][舜寿/しゅんじゅ][会/かい]}{[常務/じょうむ][理事/りじ]）}`}</RubyConvert>
                    <br />
                    <RubyConvert>{`{[飯田/いいだ][大輔/だいすけ]（[社会/しゃかい][福祉/ふくし][法人/ほうじん]}{[福祉/ふくし][楽団/がくだん]}{[理事/りじ][長/ちょう]）}`}</RubyConvert>
                  </dd>
                  <dt>
                    <RubyConvert>{`{[住所/じゅうしょ]:}`}</RubyConvert>
                  </dt>
                  <dd>
                    <RubyConvert>{`{〒261-7112}{[千葉県/ちばけん][千葉市/ちばし][美浜区/みはまく][中瀬/なかせ]2-6-1}{WBGマリブイースト12[階/かい]}`}</RubyConvert>
                  </dd>
                  <dt>メールアドレス:</dt>
                  <dd>
                    <a href="mailto:contact@f4c.jp" className={`linkUnderline`}>
                      contact@f4c.jp
                    </a>
                  </dd>
                  <dt>ウェブサイト:</dt>
                  <dd>
                    <a
                      href={`https://f4c.jp`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      https://f4c.jp
                    </a>
                  </dd>
                </dl>
              </div>
              {/* /column right*/}
            </Sticky2colmun>
          </CenterContentLayout>
        </LayoutBasic>
      </div>
    )
  }
}
export default PageBasicInformation
